"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserState = exports.OrdersState = exports.OffersState = exports.DashboardState = exports.MessagesState = exports.MetaState = void 0;
var meta_1 = require("../meta");
Object.defineProperty(exports, "MetaState", { enumerable: true, get: function () { return meta_1.state; } });
var messages_1 = require("../messages");
Object.defineProperty(exports, "MessagesState", { enumerable: true, get: function () { return messages_1.state; } });
var dashboard_1 = require("../dashboard");
Object.defineProperty(exports, "DashboardState", { enumerable: true, get: function () { return dashboard_1.state; } });
var offers_1 = require("../offers");
Object.defineProperty(exports, "OffersState", { enumerable: true, get: function () { return offers_1.state; } });
var orders_1 = require("../orders");
Object.defineProperty(exports, "OrdersState", { enumerable: true, get: function () { return orders_1.state; } });
var user_1 = require("../user");
Object.defineProperty(exports, "UserState", { enumerable: true, get: function () { return user_1.state; } });
// export type rootState = typeof MetaState &
//   typeof MessagesState &
//   typeof DashboardState &
//   typeof OffersState &
//   typeof OrdersState &
//   typeof UserState;
