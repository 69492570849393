"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const authMiddleware = async ({ store, redirect }) => {
    if (!store.state.user.authenticated) {
        // attempt to restore auth
        await store.dispatch("user/retrieveSession");
        // If still not authenticated, ask for login.
        if (!store.state.user.authenticated) {
            return redirect("/");
        }
    }
};
exports.default = authMiddleware;
