<template>
  <b-navbar class="is-primary">
    <template slot="brand">
      <b-navbar-item
        style="padding-right: 2em"
        id="brand"
        tag="router-link"
        :to="{ path: '/' }"
      >
        HEFA
      </b-navbar-item>
    </template>
    <template slot="start">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">Home</b-navbar-item>
      <b-navbar-item
        v-for="scope in availableScopes"
        :key="scope"
        tag="router-link"
        @click="activeTab = scope"
        :active="activeTab === scope"
        :to="{ path: `/${scope.toLowerCase()}` }"
      >
        {{ scope }}
      </b-navbar-item>
    </template>
    <template slot="end">
      <!-- Account information -->
      <b-navbar-item v-if="$store.state.meta.accounts">
        <b>Accounts:</b>
        <div v-for="account in $store.state.meta.accounts" :key="account">
          <p style="font-weir: '5px'" v-if="!account.hasebay">
            {{ account ? account.username : "" }},
          </p>
        </div>
      </b-navbar-item>

      <!-- Mix server switcher -->
      <div v-if="$store.state.meta.isIncubatorServer">
        <b-dropdown :triggers="['hover']" aria-role="list">
          <template #trigger>
            <b-button
              label="Switch server"
              type="is-info"
              icon-right="menu-down"
            />
          </template>

          <div
            v-for="store in $store.state.meta.incubator.incubatorStores"
            :key="store.name"
          >
            <b-dropdown-item
              aria-role="listitem"
              @click="switchIncubatorServer(store.name)"
            >
              {{ store.name }}
            </b-dropdown-item>
          </div>
        </b-dropdown>
      </div>

      <!-- Nav buttons -->
      <b-navbar-item
        v-if="$store.state.user.authenticated"
        @click="activeTab = 'Admin'"
        :active="activeTab === 'Admin'"
        tag="router-link"
        :to="{ path: '/admin' }"
      >
        <b-icon icon="cog"></b-icon>
      </b-navbar-item>

      <b-navbar-item
        tag="router-link"
        @click="activeTab = 'Profile'"
        :active="activeTab === 'Profile'"
        :to="{ path: '/profile' }"
      >
        Profile
      </b-navbar-item>
      <b-navbar-item href="/api/user/logout">Logout</b-navbar-item>
    </template>
  </b-navbar>
</template>

<style>
#brand {
  font-weight: bold;
}
</style>

<script>
export default {
  data() {
    return { activeTab: "Dashboard" };
  },
  methods: {
    async logout() {
      await this.$store.dispatch("user/logout");
    },
    async switchIncubatorServer(name) {
      await this.$store.dispatch("meta/switchIncubatorServer", name);
    },
  },
  computed: {
    availableScopes() {
      const scopes = [
        "Upload",
        "Inventory",
        "Messaging",
        "Offers",
        "Shipping",
        "Analytics",
        "Ratings",
        "Export",
        "Control",
      ];

      // TODO: scope using OIDC
      return scopes;
    },
  },
};
</script>
