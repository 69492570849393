"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleInfo = exports.notImplemented = exports.handleSuccess = exports.handleDBError = exports.handleError = void 0;
const buefy_1 = require("buefy");
const notificationQueue = [];
/**
 * Show next toast in queue and set timeout for running this function next time
 */
function processNotificationQueue() {
    if (notificationQueue.length > 0) {
        const notification = notificationQueue[0];
        notification.duration = notification.duration || 3500;
        buefy_1.ToastProgrammatic.open(notification);
        setTimeout(() => {
            notificationQueue.shift();
            processNotificationQueue();
        }, notification.duration);
    }
}
/**
 * Add notification to queue. If queue was empty, start processing it
 * otherwise it will be processed automatically
 * @param notification
 */
function addToNotificationQueue(notification) {
    notificationQueue.push(notification);
    if (notificationQueue.length === 1) {
        processNotificationQueue();
    }
}
function handleError(err, silent = false) {
    if (!err) {
        return;
    }
    console.error(err);
    const message = err.response?.data?.msg ?? err.msg ?? err.message ?? err.data ?? err;
    if (silent && !message) {
        return;
    }
    addToNotificationQueue({
        message,
        type: "is-danger",
    });
}
exports.handleError = handleError;
function handleDBError(err) {
    console.error(err);
    let msg = "Unknown error. Try again later, or contact support.";
    if (!err) {
        msg = "No data was returned!";
    }
    if (err.message.includes("E11000")) {
        msg = err.message
            .slice(err.message.indexOf("dup key: {"))
            .replace("dup key: {", "");
        msg = `An account with this ${msg.slice(0, msg.indexOf(":"))} already exists!`;
    }
    addToNotificationQueue({
        message: msg,
        type: "is-danger",
    });
}
exports.handleDBError = handleDBError;
function handleSuccess(payload, useGenericMessage = false) {
    if (useGenericMessage) {
        addToNotificationQueue({
            message: "Success!",
            type: "is-success",
        });
        return;
    }
    addToNotificationQueue({
        message: payload.msg ?? payload.message ?? payload,
        type: "is-success",
    });
}
exports.handleSuccess = handleSuccess;
function notImplemented() {
    handleInfo("This feature is not implemented yet!");
}
exports.notImplemented = notImplemented;
function handleInfo(info) {
    console.log("Info:", info);
    addToNotificationQueue({
        message: info,
        type: "is-info",
    });
}
exports.handleInfo = handleInfo;
