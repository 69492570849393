import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e586bc48 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _f47eb51a = () => interopDefault(import('../pages/admin.vue' /* webpackChunkName: "pages/admin" */))
const _2d7437ac = () => interopDefault(import('../pages/analytics.vue' /* webpackChunkName: "pages/analytics" */))
const _0de6bd81 = () => interopDefault(import('../pages/control.vue' /* webpackChunkName: "pages/control" */))
const _18687ec0 = () => interopDefault(import('../pages/export.vue' /* webpackChunkName: "pages/export" */))
const _42fef200 = () => interopDefault(import('../pages/inventory.vue' /* webpackChunkName: "pages/inventory" */))
const _44f6deb0 = () => interopDefault(import('../pages/messaging.vue' /* webpackChunkName: "pages/messaging" */))
const _169ef323 = () => interopDefault(import('../pages/offers.vue' /* webpackChunkName: "pages/offers" */))
const _7ebadbcd = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _e54cb3cc = () => interopDefault(import('../pages/ratings.vue' /* webpackChunkName: "pages/ratings" */))
const _0ae4228c = () => interopDefault(import('../pages/shipping.vue' /* webpackChunkName: "pages/shipping" */))
const _0518eae6 = () => interopDefault(import('../pages/upload.vue' /* webpackChunkName: "pages/upload" */))
const _4dba6ac6 = () => interopDefault(import('../pages/reset/_token.vue' /* webpackChunkName: "pages/reset/_token" */))
const _54515176 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _e586bc48,
    name: "404"
  }, {
    path: "/admin",
    component: _f47eb51a,
    name: "admin"
  }, {
    path: "/analytics",
    component: _2d7437ac,
    name: "analytics"
  }, {
    path: "/control",
    component: _0de6bd81,
    name: "control"
  }, {
    path: "/export",
    component: _18687ec0,
    name: "export"
  }, {
    path: "/inventory",
    component: _42fef200,
    name: "inventory"
  }, {
    path: "/messaging",
    component: _44f6deb0,
    name: "messaging"
  }, {
    path: "/offers",
    component: _169ef323,
    name: "offers"
  }, {
    path: "/profile",
    component: _7ebadbcd,
    name: "profile"
  }, {
    path: "/ratings",
    component: _e54cb3cc,
    name: "ratings"
  }, {
    path: "/shipping",
    component: _0ae4228c,
    name: "shipping"
  }, {
    path: "/upload",
    component: _0518eae6,
    name: "upload"
  }, {
    path: "/reset/:token?",
    component: _4dba6ac6,
    name: "reset-token"
  }, {
    path: "/",
    component: _54515176,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
