"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.orderFields = void 0;
exports.orderFields = `
  _id
  data
  items {
    name
    description
    images
    platforms
    meta
    price
    _id
  }
  status
  account
  platform
  source
  paypal
  external
  failed
  handled
  account
  platformMessagingSession
  createdAt
  updatedAt
  shipping {
    shipped
    provider
    trackingNumber
    temporaryTrackingAdded
  }
  refund
  notes
  tags
  meta
  address {
    name
    line1
    line2
    city
    state
    countryName
    phone
    email
    zipcode
  }
  addressFormatted
  itemVariants
  buyer {
    id
    firstName
    lastName
    country
    handle
  }
  fulfilled
  fulfillment {
    customFulfillmentAddress {
      name
      line1
      line2
      city
      state
      countryName
      phone
      email
      zipcode
    }
  }
  amount
  amountTotal
  currency
  noteFromBuyer
  paymentDisbursed
`;
